import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthProvider } from './AuthContext';
import { ThemeProvider } from './ThemeContext';
import { StoryProvider } from './StoryContext';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import SignUp from './components/SignUp';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import CreatePost from './components/CreatePost';
import CreateReel from './components/CreateReel';
import CreateStory from './components/CreateStory';
import Post from './components/Post';
import Reel from './components/Reel';
import Story from './components/Story';
import Home from './components/Home';
import AdminUsers from './components/AdminUsers';
import AdminQuestions from './components/AdminQuestions';
import Project from './components/Project';
import UserProfile from './components/UserProfile';
import PaymentSuccess from './components/PaymentSuccess';
import ContactForm from './components/ContactForm';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import Game from './components/Game';
import MetaTags from './components/MetaTags';
import CourseList from './components/CourseList';
import CourseForm from './components/CourseForm';
import CourseDetails from './components/CourseDetails';
import LessonDetails from './components/LessonDetails';
import EditQuest from './components/EditQuest';
import QuestDetails from './components/QuestDetails';
import CoursePage from './components/CoursePage';
import LessonPage from './components/LessonPage';
import Landing from './components/Landing';
import ArticleForm from './components/ArticleForm';
import ArticleList from './components/ArticleList';
import AuthorForm from './components/AuthorForm';
import CategoryForm from './components/CategoryForm';
import UploadVideoForm from "./components/UploadVideoForm";

import ProtectedRoute from './ProtectedRoute';
import ProtectedAdminRoute from './ProtectedAdminRoute';
import i18n from './i18n';


function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage); 
    }
  }, [i18n]);

  return (
    <div className="content_container">
      <MetaTags />
      
      <Router>
        <AuthProvider>
          <ThemeProvider>
            <StoryProvider>
              <div>
                <Navbar />
                <Routes>
                  <Route path="/" element={<Landing />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/reset-password/:token" element={<ResetPassword />} />
                  <Route path="/successful-payment" element={<PaymentSuccess />} />
                  <Route path="/courses" element={
                    <ProtectedAdminRoute>
                      <CourseList />
                    </ProtectedAdminRoute>
                  } />
                  <Route path="/courses/new" element={
                    <ProtectedAdminRoute>
                      <CourseForm />
                    </ProtectedAdminRoute>
                  } />
                  <Route path="/courses/:id" element={
                    <ProtectedAdminRoute>
                      <CourseDetails />
                    </ProtectedAdminRoute>
                  } />
                  <Route path="/courses/:id/lessons/:lessonId" element={
                    <ProtectedAdminRoute>
                      <LessonDetails />
                    </ProtectedAdminRoute>
                  } />
                  <Route path="/courses/:courseId/lessons/:lessonId/quests/:questId" element={
                    <ProtectedAdminRoute>
                      <QuestDetails />
                    </ProtectedAdminRoute>
                  } />
                  <Route path="/courses/:courseId/lessons/:lessonId/quests/:questId/edit" element={
                    <ProtectedAdminRoute>
                      <EditQuest />
                    </ProtectedAdminRoute>
                  } />
                  <Route path="/articles" element={
                    <ProtectedAdminRoute>
                      <ArticleList />
                    </ProtectedAdminRoute>
                  } />
                  <Route path="/articles/new" element={
                    <ProtectedAdminRoute>
                      <ArticleForm />
                    </ProtectedAdminRoute>
                  } />
                  <Route path="/authors/new" element={
                    <ProtectedAdminRoute>
                      <AuthorForm />
                    </ProtectedAdminRoute>
                  } />
                  <Route path="/category/new" element={
                    <ProtectedAdminRoute>
                      <CategoryForm />
                    </ProtectedAdminRoute>
                  } />
                  <Route path="/education/courses/:courseId" element={<CoursePage />} />
                  <Route path="/education/courses/:courseId/lessons/:lessonId" element={<LessonPage />} />
                  <Route path="/projects" element={
                    <ProtectedRoute>
                      <Project />
                    </ProtectedRoute>
                  } />            
                  <Route path="/create-post" element={
                    <ProtectedRoute>
                      <CreatePost />
                    </ProtectedRoute>
                  } />             
                  <Route path="/create-reel" element={
                    <ProtectedRoute>
                      <CreateReel />
                    </ProtectedRoute>
                  } />                
                  <Route path="/create-story" element={
                    <ProtectedRoute>
                      <CreateStory />
                    </ProtectedRoute>
                  } />            
                  <Route path="/post" element={
                    <ProtectedRoute>
                      <Post />
                    </ProtectedRoute>
                  } />            
                  <Route path="/reel" element={
                    <ProtectedRoute>
                      <Reel />
                    </ProtectedRoute>
                  } />                
                  <Route path="/story" element={
                    <ProtectedRoute>
                      <Story />
                    </ProtectedRoute>
                  } />
                  <Route path="/dashboard" element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  } />            
                  <Route path="/profile" element={
                    <ProtectedRoute>
                      <UserProfile />
                    </ProtectedRoute>
                  } />            
                  <Route path="/billing" element={
                    <ProtectedRoute>
                      <UserProfile />
                    </ProtectedRoute>
                  } />          
                  <Route path="/contact-form" element={
                    <ProtectedRoute>
                      <ContactForm />
                    </ProtectedRoute>
                  } />
                  <Route path="/game" element={
                    <ProtectedRoute>
                      <Game />
                    </ProtectedRoute>
                  } />
                  <Route path="/admin-users" element={
                    <ProtectedAdminRoute>
                      <AdminUsers />
                    </ProtectedAdminRoute>
                  } />            
                  <Route path="/admin-questions" element={
                    <ProtectedAdminRoute>
                      <AdminQuestions />
                    </ProtectedAdminRoute>
                  } />
                  <Route path="/video-translate" element={
                    <ProtectedAdminRoute>
                      <UploadVideoForm />
                    </ProtectedAdminRoute>
                  } />
                </Routes>
              </div>

              {/*<div>
               <Footer />
              </div>*/}
            </StoryProvider>
          </ThemeProvider>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
