import React, { useState } from "react";

const UploadVideoForm = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [file, setFile] = useState(null);
  const [language, setLanguage] = useState("es"); 
  const [videoId, setVideoId] = useState(null);
  const [status, setStatus] = useState("");
  const [translatedText, setTranslatedText] = useState("");

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) return;

    setStatus("Uploading...");
    setVideoId(null);
    setTranslatedText("");

    // Отправляем на Rails
    const formData = new FormData();
    formData.append("video[file]", file);
    formData.append("translation[language]", language);

    const response = await fetch(`${API_URL}/videos`, {
      method: "POST",
      body: formData
    });

    const data = await response.json();
    if (response.ok) {
      setVideoId(data.video_id);
      setStatus("Processing in background...");
    } else {
      setStatus(`Error: ${data.error || 'Something went wrong'}`);
    }
  };

  const handleCheckStatus = async () => {
    if (!videoId) return;
    const response = await fetch(`${API_URL}/videos/${videoId}`);
    const data = await response.json();
    setStatus(data.status);

    if (data.status === "done") {
      setTranslatedText(data.translated_text || "");
    }
  };

  return (
    <div>
      <h2>Upload and Translate Video</h2>
      <form onSubmit={handleSubmit}>
        <input type="file" accept="video/*" onChange={handleFileChange} />

        <select value={language} onChange={handleLanguageChange}>
          <option value="es">Spanish</option>
          <option value="de">German</option>
          <option value="en">English</option>
        </select>

        <button type="submit">Upload</button>
      </form>

      <p>Status: {status}</p>

      {videoId && (
        <button onClick={handleCheckStatus}>
          Check Status
        </button>
      )}

      {translatedText && (
        <div>
          <h3>Translated Text:</h3>
          <p>{translatedText}</p>
        </div>
      )}
    </div>
  );
};

export default UploadVideoForm;
